<template>
    <div class="pageGrid">
        
        <PageHeader title="Expenses">
            <div class="actionButtons">
                <button class="default medium round" @click="saveExpenses({})">Add</button>
            </div>
        </PageHeader>

        <div class="wrapper expensesGrid">
            <div class="block">
                <h2>Select period</h2>
                <PayRollSelector period="4" v-model:date="filter.date"/>
            </div>

            <div class="block">
                <h2>Statistics</h2>
                
                <div class="statsGrid">

                   
                    <div class="statsGroup">
                        <div class="_title"><i class="fa-solid fa-dollar-sign"></i><span>Total amount spent</span></div>
                        <div class="_value skeleton" style="height: 34px; width: 120px;" v-if="isLoading(['GetListOfExpenses'])"></div>
                        <div class="_value" v-else>${{ expenses.stats.sum ? priceConverter(expenses.stats.sum.toFixed(2)) : '0.00' }}</div>
                    </div>

                    <div class="statsGroup">
                        <div class="_title"><i class="fa-solid fa-calculator"></i><span>Number of spendings</span></div>
                        <div class="_value skeleton" style="height: 34px; width: 70px;" v-if="isLoading(['GetListOfExpenses'])"></div>
                        <div class="_value" v-else>{{ expenses.stats.total ? expenses.stats.total : 0 }}</div>
                    </div>


                </div>
                
            </div>

            <div class="block">
                <h2>Expenses</h2>
                
                <Table class="expensesTable" :cols="['Date', 'Name', 'Description', 'Amount', 'Created by', '', '']">

                    <div v-if="isLoading(['GetListOfExpenses'])">
                        <div class="row" :key="key" v-for="(item, key) of [1,2,3,4,5]">
                            <div class="col skeleton"></div>
                            <div class="col skeleton"></div>
                            <div class="col skeleton"></div>
                            <div class="col skeleton"></div>
                            <div class="col">
                                <div class="userProfile">
                                    <div class="image skeleton"></div>
                                    <div class="name skeleton"></div>
                                </div>
                            </div>
                            <div class="col skeleton"></div>
                            <div class="col skeleton"></div>
                        </div>
                    </div>

                    <div v-else>
                        <div v-if="expenses.list.length > 0">
                            <div class="row" :key="key" v-for="(item, key) of expenses.list" @dblclick="saveExpenses(item)">
                                <div class="col">{{ item.date }}</div>
                                <div class="col textNoWrap">{{ item.name }}</div>
                                <div class="col textNoWrap">{{ item.description }}</div>
                                <div class="col">${{ priceConverter(item.amount.toFixed(2)) }}</div>
                                <div class="col">
                                    <div class="userProfile" v-if="item.user">
                                        <div class="image" :style="`background-image: url('${item.user.profileImage}')`"></div>
                                        <div class="name">{{ item.user.name }}</div>
                                    </div>
                                </div>
                                <button class="default small reverse" @click.stop="saveExpenses(item)">Edit</button>
                                <button class="default small reverse trash" @click="deleteItem(item._id, key)"><i class="fa-solid fa-trash-can"></i></button>
                            </div>
                        </div>
                        <div class="noItemsMessage" v-else>
                            <div class="icon"><i class="fa-solid fa-receipt"></i></div>
                            <div class="message">No items found for selected period of time.</div>
                        </div>
                    </div>

                </Table>
                
            </div>

        </div>
        
    </div>
</template>

<script>
import moment from 'moment';
import PayRollSelector from '../../components/PayRollSelector.vue'
import SaveExpenses from './Save.vue'
    export default {
        components: {
            PayRollSelector
        },
        data() {
            return {
                filter: {
                    date: null
                },
                expenses: { stats: {}, list: [] }
            }
        },
        computed: {
            
        },
        methods: {
            async deleteItem(id, key) {
                let confirmDelete = await confirm("Do you really wish to delete this item from the list?");
                if(!confirmDelete) return;
                this.expenses.splice(key, 1);
                this.ajax('HandleItemRemove', {
                    url: '/expenses',
                    method: 'DELETE',
                    data: {
                        _id: id
                    }
                }, (err, body) => { });
            },
            saveExpenses(item) {
                this.$showModalSimple(SaveExpenses, item._id ? 'Edit item' : 'Add item', {
                    item: item
                }, (data) => {
                    if(data && data.ok) {
                        this.getExpenses('HandleRefreshAfterAdd');
                    }
                });
            },
            getExpenses(name = 'GetListOfExpenses') {
                let query = new URLSearchParams(this.filter).toString();
                this.ajax(name, {
                    url: '/expenses?' + query,
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.expenses = body;
                });
            }
        },
        watch: {
            filter: {
                deep: true,
                handler() {
                    this.getExpenses();
                }
            }
        },
        mounted() {
            this.getExpenses();
        }
    }
</script>


<style lang="scss">
.expensesTable {
    .row {
        grid-template-columns: 120px minmax(0, 0.5fr) minmax(0, 1fr) 100px 200px 100px 34px !important;
    }
}
</style>

<style lang="scss" scoped>


.userProfile {
    display: grid;
    grid-template-columns: 28px auto;
    column-gap: 10px;
    align-items: center;
    .image {
        width: 28px;
        height: 28px;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        overflow: hidden;
    }
    .name {
        font-size: 12px;
    }
}
.expensesTable {
    .row {
        display: grid;
        grid-template-columns: 120px minmax(0, 0.5fr) minmax(0, 1fr) 100px 200px 100px 34px !important;
        align-items: center;
        padding: 10px 20px;
        column-gap: 20px;

        &:nth-child(2n) {
            background: $bg;
        }
        &:last-child {
            border-radius: 0 0 $borderRadius $borderRadius;
        }
    }
}

.statsGrid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 20px;
}

.skeleton {
    min-height: 20px;
    position: relative;
    overflow: hidden;
    background: $borderColor;
    &::after {
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to left, rgba(255,255,255, .0), rgba(255,255,255, .3), rgba(255,255,255, .5), rgba(255,255,255, .3), rgba(255,255,255, .0));
        background-image: -moz-linear-gradient(to left, rgba(255,255,255, .0), rgba(255,255,255, .3), rgba(255,255,255, .5), rgba(255,255,255, .3), rgba(255,255,255, .0));
        background-image: -webkit-linear-gradient(to left, rgba(255,255,255, .0), rgba(255,255,255, .3), rgba(255,255,255, .5), rgba(255,255,255, .3), rgba(255,255,255, .0));
        animation: placeholderSkeleton 2s infinite;
        content: '';
    }
}

.dark-theme {
    .skeleton {
        &::after {
            background-image: linear-gradient(to left, rgba(0,0,0, .0), rgba(0,0,0, .1), rgba(0,0,0, .12), rgba(0,0,0, .1), rgba(0,0,0, .0));
            background-image: -moz-linear-gradient(to left, rgba(0,0,0, .0), rgba(0,0,0, .1), rgba(0,0,0, .12), rgba(0,0,0, .1), rgba(0,0,0, .0));
            background-image: -webkit-linear-gradient(to left, rgba(0,0,0, .0), rgba(0,0,0, .1), rgba(0,0,0, .12), rgba(0,0,0, .1), rgba(0,0,0, .0));
        }
    }
}



.pageGrid {
    display: grid;
}

.block {
    display: grid;
    row-gap: 20px;
}

.expensesGrid {
    display: grid;
    row-gap: 30px;
}

.trash {
    color: $error !important;
    font-size: 12px;
}


.noItemsMessage {
    padding: 100px 30px;
    display: grid;
    justify-items: center;
    align-items: center;
    row-gap: 30px;
    .icon {
        font-size: 30px;
        opacity: 0.95;
    }
    .message {
        font-size: 13px;
    }
}

@-webkit-keyframes placeholderSkeleton {
    0% {
        left: -100%;
    }
    100%{
        left: 100%;
    }
}

</style>