<template>
    <div>

        <div class="multiWeekSelection">

            <button class="_btn" @click="prevGroup"><i class="fas fa-caret-left"></i></button>
            <div :key="key" v-for="(group, key) of twoWeekPeriods" class="weekBtn" @click="selectedDate = group.start">
                <button class="weekSelection" :class="{ 'active' : selectedDate == group.start }">
                    <span v-if="selectedDate == group.start"><i class="fas fa-check"></i></span>
                </button>
                <div class="weenNum">{{ group.start }}</div>
                <div class="weenNum">{{ group.end }}</div>
            </div>
            <button class="_btn" @click="nextGroup"><i class="fas fa-caret-right"></i></button>

        </div>

    </div>
</template>

<script>
import moment from 'moment';

    export default {
        props: ['period'],
        data() {
            return {
                weekStep: this.period || 2,
                selectedDate: null,
                updateStep: 2,
                currentPayRollPeriod: null
            }
        },
        methods: {
            getCurrentPayRollPeriod() {
                this.$axios.get(`/reports/GetCurrentPayRollDate?period=${this.weekStep}`)
                .then(data => {
                    this.currentPayRollPeriod = data.data;
                    this.selectedDate = this.currentPayRollPeriod.fromString;
                })
                .catch(err => {
                    alert("Something went wrong. Try again later!");
                });
            },
            prevGroup() {
                this.updateStep--;
            },
            nextGroup() {
                this.updateStep++;
            }
        },
        computed: {
            twoWeekPeriods() {

                if(!this.currentPayRollPeriod) return [];
                let groups = [];

                let group = moment(this.currentPayRollPeriod.fromString, "MM/DD/YYYY");
                if(!group) return [];

                group = group.clone().add(this.weekStep * this.updateStep, 'weeks');

                for(let i = 1; i <= 7; i++) {
                    let tempGroup = group.clone();
                    let groupID = tempGroup.year() + "W" + tempGroup.isoWeek();
                    groups.push({
                        start: tempGroup.format("MM/DD/YYYY"),
                        end: tempGroup.clone().add(this.weekStep - 1, 'weeks').endOf('isoWeek').format("MM/DD/YYYY"),
                        groupID: groupID
                    });
                    group.subtract(this.weekStep, 'weeks');
                }
                
                return groups.reverse();
            }
        },
        mounted () {
            this.getCurrentPayRollPeriod();
        },
        watch: {
            selectedDate() {
                this.$emit('update:date', this.selectedDate);
            },
            period() {
                this.weekStep = this.period;
                this.getCurrentPayRollPeriod();
            }
        }
    }
</script>

<style lang="scss" scoped>
.multiWeekSelection {
    display: grid;
    grid-template-columns: 100px repeat(7, minmax(0, 1fr)) 100px;
    align-content: stretch;
    text-align: center;
    height: 100px;
    background: $bgSecondary;
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
    ._btn {
        height: 100%;
        background: transparent;
        border: 0;
        font-size: 20px;
    }
}

.weekBtn {
    height: 100%;
    display: grid;
    row-gap: 5px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    border-right: 1px solid $borderColor;
    cursor: pointer;
    user-select: none;
    transition: ease 0.5s;
    &:first-of-type {
        border-left: 1px solid $borderColor;
    }
    &:hover {
        background: $buttonColor2;
    }
}


.weekSelection {
    width: 34px;
    height: 34px;
    font-size: 12px;
    border: 2px solid $borderColor;
    border-radius: 50%;
    text-align: center;
    background: transparent;
    padding: 0;
    &.active {
        color: $buttonColor;
        background: $buttonColor2;
        border: 2px solid $buttonColor;
    }
}
</style>