<template>
    <div class="saveFormHolder">

        <div class="formGrid">
            <div class="input">
                <div class="_header">
                    <label>Date</label>
                    <span class="_error">{{ errors.date }}</span>
                </div>
                <DatePicker class="datePickerButtonHolder" v-model="item.date" position="left" :simple="true"/>
            </div>
            
            <Input name="Name" placeholder="Name" v-model="item.name" :error="errors.name"/>
            <Input name="Description" placeholder="Short Description" v-model="item.description" :error="errors.description"/>
            <Input name="Amount" placeholder="Amount spent" v-model="item.amount" :error="errors.amount"/>
        </div>

        <div class="actionButtons">
            <button class="default medium" @click="handleSave" :disabled="isLoading(['HandleSaveExpenses'])">{{ isLoading(['HandleSaveExpenses']) ? 'Saving' : 'Save' }}</button>
        </div>

    </div>
</template>

<script>
import DatePicker from '../../components/DatePicke.vue'

    export default {
        components: {
            DatePicker
        },
        props: ['options'],
        data() {
            return {
                item: this.options.item || {},
                errors: {}
            }
        },
        methods: {
            handleSave() {
                this.errors = {};
                this.ajax('HandleSaveExpenses', {
                    url: '/expenses',
                    method: 'POST',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$emit('close', { ok: true });
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.formGrid {
    display: grid;
    row-gap: 15px;
}

.datePickerButtonHolder {
    border: 1px solid $borderColor;
    border-radius: 5px;
    box-shadow: $boxShadow;
    background: $bgSecondary;
    width: 100%;
}

.saveFormHolder {
    display: grid;
    row-gap: 20px;
}

.actionButtons {
    display: grid;
    justify-content: end;
}
</style>